import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
// import { FooterService } from "./footer.service";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    // constructor(private footerService: FooterService) {}
    ngOnInit(): void {}

    // myReactiveForm = new FormGroup({
    //   'u_email': new FormControl(null, Validators.required)
    // });

    // newsletter_msg_class = 'text-danger';
    // newsletter_msg_show = false;
    // newsletter_msg = '';

    // loader_show = false;

    // validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // newsletter_send(){
    //   if(this.myReactiveForm.status == 'VALID'){
    //     let email = this.myReactiveForm.value.u_email;
    //     if(email == ''){
    //       this.newsletter_msg_show = true;
    //       this.newsletter_msg = 'Please enter email';
    //       setTimeout(()=>{
    //         this.newsletter_msg_show = false;
    //         this.newsletter_msg_class = 'text-danger';
    //         this.newsletter_msg = '';
    //       }, 4000);
    //     }else if(!email.match(this.validRegex)){
    //       this.newsletter_msg_show = true;
    //       this.newsletter_msg = 'Please enter valid email';
    //       setTimeout(()=>{
    //         this.newsletter_msg_show = false;
    //         this.newsletter_msg_class = 'text-danger';
    //         this.newsletter_msg = '';
    //       }, 4000);
    //     }else{
    //       this.loader_show = true;
    //       const data = this.myReactiveForm.value;
    //       this.footerService.newsletter_mail_send(data).subscribe((res:any)=>{
    //         this.newsletter_msg_show = true;
    //         if(res.status == 1){
    //           this.newsletter_msg_class = 'text-success';
    //           this.newsletter_msg = res.msg;
    //         }else{
    //           this.newsletter_msg_class = 'text-danger';
    //           this.newsletter_msg = res.msg;
    //         }
    //         this.loader_show = false;
    //         setTimeout(()=>{
    //           this.newsletter_msg_show = false;
    //           this.newsletter_msg_class = 'text-danger';
    //           this.newsletter_msg = '';
    //         }, 4000);
    //       });
    //     }
    //   }
    // }
}
