<section class="position-relative m-0 p-0">
    <!-- <div class="w-100 h-100 m-0 p-0 overflow-hidden">
        <video
            class="video-intro w-100 h-100 overflow-hidden"
            poster="../../../../../assets//img/bg-video_2.mp4"
            playsinline
            autoplay
            muted
            loop
        >
            <source
                src="../../../../../assets//img/bg-video_2.mp4"
                type="video/mp4"
            />
        </video>
    </div> -->

    <!-- <div class="container position-absolute top-0 left-50 mx-auto pt-70">
        <div class="mission-content">
            <div class="section-title text-start">
                <span class="sub-title">Discover Learning</span>
                <h2>Why our platform in better</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <h3>
                            EMBRACE THE NET ZERO FUTURE!
                        </h3>
                        <p>
                            ENVIASK LEGAL (OPC) PVT. LTD. is a rapidly growing
                            Techno-Legal Service Provider Company specializing
                            in various facets of environmental law,
                            environmental science, and environmental
                            engineering. With a focus on Climate Change
                            Mitigation Measures and Green Technologies, the
                            company also extends its expertise to intellectual
                            property services within the environmental sector.
                            The team at ENVIASK comprises highly competent and
                            experienced experts with over 15 years of collective
                            experience in the Enviro-Legal field.
                        </p>

                        <a routerLink="/" class="default-btn"
                            ><i class="bx bx-book-reader icon-arrow before"></i
                            ><span class="label">View Courses</span
                            ><i class="bx bx-book-reader icon-arrow after"></i
                        ></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>CARBON NEUTRAL: WHERE SUSTAINABILITY MEETS RESPONSIBILITY.</h3>
                        <p>
                            <b>Vision Statement: </b> "We envision a world where
                            innovation thrives, environments flourish, and
                            intellectual creations shape a sustainable future.
                            At Enviask Legal (OPC) Pvt. Ltd., our vision is to
                            be the catalyst for positive change, guiding
                            businesses towards prosperity while leaving a
                            lasting, positive impact on the global community."
                        </p>

                        <a routerLink="/" class="default-btn"
                            ><i class="bx bx-book-reader icon-arrow before"></i
                            ><span class="label">View Courses</span
                            ><i class="bx bx-book-reader icon-arrow after"></i
                        ></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>SMALL STEPS, GIANT LEAPS: TOWARDS A CARBON-FREE TOMORROW.</h3>
                        <p>
                            <b> Mission Statement:</b> "Our mission is to lead
                            industries with expert guidance in the Enviro-Legal
                            and Intellectual Property domains. We are committed
                            to providing comprehensive solutions that seamlessly
                            integrate legal compliance and technical innovation.
                            Through a client-centric approach, we navigate the
                            complexities of environmental regulations, offering
                            sustainable and responsible strategies."
                        </p>

                        <a routerLink="/" class="default-btn"
                            ><i class="bx bx-book-reader icon-arrow before"></i
                            ><span class="label">View Courses</span
                            ><i class="bx bx-book-reader icon-arrow after"></i
                        ></a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>ECO-FRIENDLY TODAY, CARBON-FREE TOMORROW.</h3>
                        <p>
                            <b>Value Statement: </b>ENVIASK LEGAL (OPC) PVT.
                            LTD. is a dynamic Techno-Legal Service Provider,
                            driven by the values of integrity, expertise, and
                            innovation. With a client-centric approach, we offer
                            cutting-edge solutions, blending legal acumen with
                            technical innovation to navigate environmental
                            complexities, foster sustainability, and build
                            lasting partnerships based on resilience and
                            collaboration. Our commitment to excellence is
                            underlined by a team of seasoned professionals
                            dedicated to upholding the highest standards of
                            integrity while championing innovation and
                            sustainability in every aspect of our work.
                        </p>

                        <a routerLink="/" class="default-btn"
                            ><i class="bx bx-book-reader icon-arrow before"></i
                            ><span class="label">View Courses</span
                            ><i class="bx bx-book-reader icon-arrow after"></i
                        ></a>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div> -->

    <!-- <div class="row tabs mt-5">
        <div class="col-12">
            <div class="faq-area pt-100 pb-70">
                <div class="">
                    <div class="faq-accordion-tab about_tab">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Indtoduction">
                                <p class="mb-0 container">
                                    ENVIASK LEGAL (OPC) PVT. LTD. is a rapidly
                                    growing Techno-Legal Service Provider
                                    Company specializing in various facets of
                                    environmental law, environmental science,
                                    and environmental engineering. With a focus
                                    on Climate Change Mitigation Measures and
                                    Green Technologies, the company also extends
                                    its expertise to intellectual property
                                    services within the environmental sector.
                                    The team at ENVIASK comprises highly
                                    competent and experienced experts with over
                                    15 years of collective experience in the
                                    Enviro-Legal field.
                                </p>
                            </ngx-tab>
                            <ngx-tab tabTitle="Vision">
                                <p class="mb-0 container">
                                    We envision a world where innovation
                                    thrives, environments flourish, and
                                    intellectual creations shape a sustainable
                                    future. At Enviask Legal (OPC) Pvt. Ltd.,
                                    our vision is to be the catalyst for
                                    positive change, guiding businesses towards
                                    prosperity while leaving a lasting, positive
                                    impact on the global community.
                                </p>
                            </ngx-tab>
                            <ngx-tab tabTitle="Mission">
                                <p class="mb-2 container">
                                    Our mission is to lead industries with
                                    expert guidance in the Enviro-Legal and
                                    Intellectual Property domains. We are
                                    committed to providing comprehensive
                                    solutions that seamlessly integrate legal
                                    compliance and technical innovation. Through
                                    a client-centric approach, we navigate the
                                    complexities of environmental regulations,
                                    offering sustainable and responsible
                                    strategies.
                                </p>
                            </ngx-tab>
                            <ngx-tab tabTitle="Values">
                                <p class="mb-2 container">
                                    ENVIASK LEGAL (OPC) PVT. LTD. is a dynamic
                                    Techno-Legal Service Provider, driven by the
                                    values of integrity, expertise, and
                                    innovation. With a client-centric approach,
                                    we offer cutting-edge solutions, blending
                                    legal acumen with technical innovation to
                                    navigate environmental complexities, foster
                                    sustainability, and build lasting
                                    partnerships based on resilience and
                                    collaboration. Our commitment to excellence
                                    is underlined by a team of seasoned
                                    professionals dedicated to upholding the
                                    highest standards of integrity while
                                    championing innovation and sustainability in
                                    every aspect of our work.
                                </p>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>
