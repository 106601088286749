<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <!-- <li>FAQ's</li> -->
            </ul>
            <h2>Techno-Legal Solution in the Environmental Domain</h2>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ngx-tabset>
                <ngx-tab tabTitle="Getting Started">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Environmental Law Services</h3>
                                <p>
                                    Offering comprehensive legal guidance and
                                    compliance solutions in the dynamic field of
                                    environmental law, ensuring clients navigate
                                    regulatory landscapes with precision and
                                    compliance.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Environmental Science and Engineering</h3>
                                <p>
                                    Leveraging a team of experts in
                                    environmental science and engineering to
                                    provide in-depth analysis, assessment, and
                                    strategic solutions for sustainable and
                                    responsible practices.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Climate Change Mitigation Measures</h3>
                                <p>
                                    Developing and implementing strategies to
                                    address and mitigate the impact of climate
                                    change, aligning businesses with global
                                    sustainability goals and regulations.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Green Technologies Consulting</h3>
                                <p>
                                    Providing consultation on cutting-edge green
                                    technologies, facilitating the integration
                                    of eco-friendly solutions into business
                                    processes for enhanced efficiency and
                                    environmental responsibility.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Legal Compliance and Regulatory Navigation
                                </h3>
                                <p>
                                    Assisting clients in navigating complex
                                    environmental regulations, ensuring legal
                                    compliance, and developing strategies for
                                    proactive adherence to evolving legal
                                    frameworks.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Sustainability Strategies</h3>
                                <p>
                                    Developing and implementing sustainable
                                    business strategies that not only comply
                                    with environmental regulations but also
                                    contribute to a positive ecological impact
                                    and corporate responsibility.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Client-Centric Environmental Solutions</h3>
                                <p>
                                    Tailoring services to meet the unique needs
                                    of our clients, ensuring that our
                                    environmental solutions align with their
                                    strategic objectives while maintaining a
                                    client-centric focus.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Circular Economy Solutions</h3>
                                <p>
                                    Advancing circular economy practices,
                                    guiding businesses in the design and
                                    implementation of sustainable product life
                                    cycles, waste reduction, and resource
                                    efficiency.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Environmental Credits Management</h3>
                                <p>
                                    Specialized services encompassing
                                    <b
                                        >Carbon Credits, Water Credits, Plastic
                                        Credits, and Green Credits management</b
                                    >. Assisting clients in navigating credit
                                    markets, calculating footprints, and
                                    implementing offset initiatives to support
                                    sustainability goals.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Sustainable Farming Practices</h3>
                                <p>
                                    Providing expertise in sustainable farming
                                    practices, offering guidance on eco-friendly
                                    agricultural methods, resource optimization,
                                    and environmentally responsible farming
                                    strategies.
                                </p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Pricing & Plans">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What’s the difference between college and
                                    university?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of
                                    undergraduate degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of graduate
                                    degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Can you work while studying in the United
                                    States?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Sales Question">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What’s the difference between college and
                                    university?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Can you work while studying in the United
                                    States?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of
                                    undergraduate degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of graduate
                                    degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Usage Guides">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What’s the difference between college and
                                    university?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of
                                    undergraduate degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of graduate
                                    degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Can you work while studying in the United
                                    States?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Usage Guides">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What’s the difference between college and
                                    university?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of
                                    undergraduate degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: What are the different types of graduate
                                    degrees?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>
                                    Q: Can you work while studying in the United
                                    States?
                                </h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p>
                                    <strong>A:</strong> Colleges offer only
                                    undergraduate degrees while universities
                                    offer graduate degrees as well, but the
                                    terms are often used interchangeably.
                                </p>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner>
