<app-header-style-one></app-header-style-one>
<div class="hero-section">
    <video
        autoplay
        playsinline
        muted
        loop
        poster="../../../../../assets//img/bg-video_2.mp4"
        id="heroVideo"
    >
        <source
            src="../../../../../assets//img/bg-video_2.mp4"
            type="video/mp4"
            autoplay
            playsinline
            muted
            loop
        />
        Your browser does not support the video tag.
    </video>
    <div class="overlay"></div>
    <div class="content">
        <!-- Your hero content goes here -->
        <h1>Enviask</h1>
        <p>nature</p>
        <a routerLink="/" class="default-btn"
            ><i class="bx bx-move-horizontal icon-arrow before"></i
            ><span class="label">Know More</span
            ><i class="bx bx-move-horizontal icon-arrow after"></i
        ></a>
    </div>
</div>
<!-- <app-homeone-main-banner></app-homeone-main-banner> -->

<div class="row tabs mt-5">
    <div class="col-12">
        <div class="faq-area pt-100 pb-70">
            <div class="">
                <div class="faq-accordion-tab about_tab">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Indtoduction">
                            <p class="mb-0 container">
                                ENVIASK LEGAL (OPC) PVT. LTD. is a rapidly
                                growing Techno-Legal Service Provider Company
                                specializing in various facets of environmental
                                law, environmental science, and environmental
                                engineering. With a focus on Climate Change
                                Mitigation Measures and Green Technologies, the
                                company also extends its expertise to
                                intellectual property services within the
                                environmental sector. The team at ENVIASK
                                comprises highly competent and experienced
                                experts with over 15 years of collective
                                experience in the Enviro-Legal field.
                            </p>
                        </ngx-tab>
                        <ngx-tab tabTitle="Vision">
                            <p class="mb-0 container">
                                We envision a world where innovation thrives,
                                environments flourish, and intellectual
                                creations shape a sustainable future. At Enviask
                                Legal (OPC) Pvt. Ltd., our vision is to be the
                                catalyst for positive change, guiding businesses
                                towards prosperity while leaving a lasting,
                                positive impact on the global community.
                            </p>
                        </ngx-tab>
                        <ngx-tab tabTitle="Mission">
                            <p class="mb-2 container">
                                Our mission is to lead industries with expert
                                guidance in the Enviro-Legal and Intellectual
                                Property domains. We are committed to providing
                                comprehensive solutions that seamlessly
                                integrate legal compliance and technical
                                innovation. Through a client-centric approach,
                                we navigate the complexities of environmental
                                regulations, offering sustainable and
                                responsible strategies.
                            </p>
                        </ngx-tab>
                        <ngx-tab tabTitle="Values">
                            <p class="mb-2 container">
                                ENVIASK LEGAL (OPC) PVT. LTD. is a dynamic
                                Techno-Legal Service Provider, driven by the
                                values of integrity, expertise, and innovation.
                                With a client-centric approach, we offer
                                cutting-edge solutions, blending legal acumen
                                with technical innovation to navigate
                                environmental complexities, foster
                                sustainability, and build lasting partnerships
                                based on resilience and collaboration. Our
                                commitment to excellence is underlined by a team
                                of seasoned professionals dedicated to upholding
                                the highest standards of integrity while
                                championing innovation and sustainability in
                                every aspect of our work.
                            </p>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
        </div>
    </div>
</div>

<app-homeone-about id="about"></app-homeone-about>

<div class="courses-categories-area pt-70 pb-70">
    <div class="container">
        <div class="section-title text-center mb-0">
            <h2 class="">Services</h2>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>

<!-- <div class="courses-categories-area bg-image pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-1" class="default-btn"
                ><i class="bx bx-show-alt icon-arrow before"></i
                ><span class="label">View All</span
                ><i class="bx bx-show-alt icon-arrow after"></i
            ></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div> -->

<!-- <div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<app-homeone-courses></app-homeone-courses> -->

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>COLLABRATIONS</h2>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->

<!-- <div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"
                ><i class="bx bx-book-reader icon-arrow before"></i
                ><span class="label">Read All</span
                ><i class="bx bx-book-reader icon-arrow after"></i
            ></a>
        </div>
        <app-blog></app-blog>
    </div>
</div> -->
