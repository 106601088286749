<app-header-style-one></app-header-style-one>

<section class="faq py-5 px-xl-5">
    <div class="container py-2">
        <div class="row">
            <h2 class="faq-title mb-3 mb-md-5 gothamb">
                Collaborations With Industries
            </h2>
        </div>
    </div>
    <div class="container">
        <div class="row mx-auto">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item" (click)="changeAccordion('Five')">
                    <h2 class="accordion-header" id="headingFive">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                            (click)="change_accordion('5')"
                        >
                            Understanding Industry-Specific Challenges:
                            <i class="bx bx-plus-medical" id="plus_5"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Begin by comprehensively understanding the
                            industry's specific challenges, operational
                            processes, and current compliance mechanisms.
                            Identify key parameters within their EHS policy and
                            compliance framework.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Six')">
                    <h2 class="accordion-header" id="headingSix">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            (click)="change_accordion('6')"
                        >
                            EHS Policy Alignment
                            <i class="bx bx-plus-medical" id="plus_6"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Work closely with industry stakeholders to align
                            your services with their EHS policy. Tailor
                            solutions to address specific compliance
                            requirements, ensuring that they meet or exceed
                            regulatory standards while integrating sustainable
                            practices.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Seven')">
                    <h2 class="accordion-header" id="headingSeven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            (click)="change_accordion('15')"
                        >
                            Environmental Audits and Risk Assessments
                            <i class="bx bx-plus-medical" id="plus_7"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Conduct thorough environmental audits and risk
                            assessments to identify potential areas of
                            improvement. Assess the environmental impact,
                            health, and safety risks associated with current
                            practices, providing actionable recommendations for
                            mitigation and enhancement.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eight')">
                    <h2 class="accordion-header" id="headingEight">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            (click)="change_accordion('16')"
                        >
                            Customized Training Programs
                            <i class="bx bx-plus-medical" id="plus_8"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Develop customized training programs for industry
                            personnel, focusing on EHS compliance, sustainable
                            development, and climate change mitigation. Ensure
                            that training is practical, relevant, and addresses
                            the specific needs of the industry.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Nine')">
                    <h2 class="accordion-header" id="headingNine">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="true"
                            aria-controls="collapseNine"
                            (click)="change_accordion('9')"
                        >
                            Green Technologies Integration:
                            <i class="bx bx-plus-medical" id="plus_9"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseNine"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Explore opportunities to integrate green
                            technologies within the industry's operations.
                            Provide guidance on adopting energy-efficient
                            processes, waste reduction strategies, and
                            eco-friendly technologies that align with
                            sustainable development and climate change goals.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Ten')">
                    <h2 class="accordion-header" id="headingTen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            (click)="change_accordion('10')"
                        >
                            Carbon Footprint Reduction Strategies
                            <i class="bx bx-plus-medical" id="plus_10"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Collaborate on initiatives to reduce carbon
                            footprint. This may involve implementing
                            energy-saving measures, promoting renewable energy
                            adoption, and exploring carbon offset initiatives
                            that contribute to the industry's climate goals.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eleven')">
                    <h2 class="accordion-header" id="headingEleven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            (click)="change_accordion('11')"
                        >
                            Circular Economy Implementation
                            <i class="bx bx-plus-medical" id="plus_11"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEleven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEleven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Introduce circular economy principles to enhance
                            resource efficiency. Work with industries to design
                            processes that minimize waste, encourage recycling,
                            and extend product life cycles, aligning with
                            sustainable development objectives.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Twelve')">
                    <h2 class="accordion-header" id="headingTwelve">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            (click)="change_accordion('12')"
                        >
                            Regulatory Compliance Assistance
                            <i class="bx bx-plus-medical" id="plus_12"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTwelve"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Assistance: Assist industries in staying abreast of
                            evolving environmental regulations. Provide
                            proactive support in adapting to new compliance
                            requirements, ensuring a seamless integration of
                            sustainable practices and climate-centric solutions.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Thirteen')"
                >
                    <h2 class="accordion-header" id="headingThirteen">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="true"
                            aria-controls="collapseThirteen"
                            (click)="change_accordion('13')"
                        >
                            Sustainable Supply Chain Management
                            <i class="bx bx-plus-medical" id="plus_13"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseThirteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThirteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Collaborate on developing sustainable supply chain
                            practices. Work with industry partners to assess and
                            enhance the sustainability of their supply chains,
                            considering factors such as responsible sourcing and
                            ethical business practices.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Fourteen')"
                >
                    <h2 class="accordion-header" id="headingFourteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            (click)="change_accordion('14')"
                        >
                            Participation in Industry Forums and Initiatives
                            <i class="bx bx-plus-medical" id="plus_14"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFourteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Actively participate in industry forums and
                            initiatives related to sustainability and climate
                            action. This involvement helps build credibility,
                            fosters collaboration, and positions ENVIASK LEGAL
                            as a thought leader in sustainable development.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Fifteen')"
                >
                    <h2 class="accordion-header" id="headingFifteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#headingFifteen"
                            aria-expanded="false"
                            aria-controls="headingFifteen"
                            (click)="change_accordion('15')"
                        >
                            Performance Monitoring and Reporting
                            <i class="bx bx-plus-medical" id="plus_15"></i>
                        </button>
                    </h2>
                    <div
                        id="headingFifteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFifteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Establish mechanisms for monitoring the performance
                            of implemented solutions. Develop robust reporting
                            systems to track progress against environmental
                            goals, EHS policy parameters, and sustainability
                            metrics.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Sixteen')"
                >
                    <h2 class="accordion-header" id="headingSixteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                            (click)="change_accordion('16')"
                        >
                            Continuous Improvement and Innovation
                            <i class="bx bx-plus-medical" id="plus_16"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFourteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Encourage a culture of continuous improvement and
                            innovation within the industry. Collaborate on
                            research and development projects that explore
                            cutting-edge technologies and practices for
                            achieving both compliance and sustainable
                            development objectives.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
