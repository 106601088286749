<app-header-style-one></app-header-style-one>

<section class="faq py-5 px-xl-5">
    <div class="container py-2">
        <div class="row">
            <h2 class="faq-title mb-3 mb-md-5 gothamb">
                Collaborations with NGOs
            </h2>
        </div>
    </div>
    <div class="container">
        <div class="row mx-auto">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item" (click)="changeAccordion('Five')">
                    <h2 class="accordion-header" id="headingFive">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                            (click)="change_accordion('5')"
                        >
                            Identify Common Objectives
                            <i class="bx bx-plus-medical" id="plus_5"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Initiate a dialogue with NGOs that share common
                            objectives in the environmental and educational
                            sectors. Identify key areas of interest, ensuring
                            that the collaboration aligns with your company's
                            CSR goals and the NGO's mission.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Six')">
                    <h2 class="accordion-header" id="headingSix">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            (click)="change_accordion('6')"
                        >
                            Needs Assessment and Impact Analysis
                            <i class="bx bx-plus-medical" id="plus_6"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Conduct a needs assessment in collaboration with the
                            NGO to identify specific challenges and
                            opportunities in the selected sectors. Analyze the
                            potential social and environmental impact of
                            proposed CSR initiatives.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Seven')">
                    <h2 class="accordion-header" id="headingSeven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            (click)="change_accordion('15')"
                        >
                            Formulate Clear Objectives and Outcomes
                            <i class="bx bx-plus-medical" id="plus_7"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Clearly define the objectives and expected outcomes
                            of the collaboration. Establish measurable goals and
                            key performance indicators (KPIs) to track the
                            impact of CSR activities on both environmental and
                            educational fronts.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eight')">
                    <h2 class="accordion-header" id="headingEight">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            (click)="change_accordion('16')"
                        >
                            Develop Tailored CSR Programs
                            <i class="bx bx-plus-medical" id="plus_8"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Work closely with the NGO to develop tailored CSR
                            programs that address identified needs. This could
                            involve environmental conservation projects,
                            sustainable development initiatives, and educational
                            programs that promote access to quality education.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Nine')">
                    <h2 class="accordion-header" id="headingNine">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="true"
                            aria-controls="collapseNine"
                            (click)="change_accordion('9')"
                        >
                            Capacity Building and Training
                            <i class="bx bx-plus-medical" id="plus_9"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseNine"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Collaborate on capacity-building initiatives,
                            including training programs for community members,
                            educators, and stakeholders. These programs can
                            focus on environmental conservation practices,
                            sustainable farming, and educational empowerment.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Ten')">
                    <h2 class="accordion-header" id="headingTen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            (click)="change_accordion('10')"
                        >
                            Educational Infrastructure Development
                            <i class="bx bx-plus-medical" id="plus_10"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Explore opportunities to contribute to educational
                            infrastructure development. This may include
                            supporting the construction of schools, libraries,
                            or classrooms, as well as providing necessary
                            resources such as books, computers, and educational
                            materials.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eleven')">
                    <h2 class="accordion-header" id="headingEleven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            (click)="change_accordion('11')"
                        >
                            Environmental Awareness Campaigns
                            <i class="bx bx-plus-medical" id="plus_11"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEleven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEleven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Partner with NGOs to launch environmental awareness
                            campaigns. These campaigns can include tree planting
                            drives, waste management initiatives, and community
                            workshops on sustainable living practices.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Twelve')">
                    <h2 class="accordion-header" id="headingTwelve">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            (click)="change_accordion('12')"
                        >
                            Scholarship Programs
                            <i class="bx bx-plus-medical" id="plus_12"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTwelve"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Collaborate on scholarship programs to support
                            students from underprivileged backgrounds. Provide
                            financial aid, mentorship, and resources to enhance
                            educational opportunities for deserving individuals.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Thirteen')"
                >
                    <h2 class="accordion-header" id="headingThirteen">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="true"
                            aria-controls="collapseThirteen"
                            (click)="change_accordion('13')"
                        >
                            Joint Advocacy and Policy Initiatives
                            <i class="bx bx-plus-medical" id="plus_13"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseThirteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThirteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Engage in joint advocacy efforts with the NGO to
                            influence policy changes related to environmental
                            protection and education. Collaborate on initiatives
                            that contribute to the development of sustainable
                            policies and practices.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Fourteen')"
                >
                    <h2 class="accordion-header" id="headingFourteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            (click)="change_accordion('14')"
                        >
                            Employee Engagement Programs
                            <i class="bx bx-plus-medical" id="plus_14"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFourteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Encourage employee involvement in CSR activities.
                            Develop volunteering programs that allow employees
                            to actively participate in environmental and
                            educational initiatives, fostering a sense of social
                            responsibility and teamwork.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Fifteen')"
                >
                    <h2 class="accordion-header" id="headingFifteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#headingFifteen"
                            aria-expanded="false"
                            aria-controls="headingFifteen"
                            (click)="change_accordion('15')"
                        >
                            Transparent Reporting and Communication
                            <i class="bx bx-plus-medical" id="plus_15"></i>
                        </button>
                    </h2>
                    <div
                        id="headingFifteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFifteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Establish transparent reporting mechanisms to
                            communicate the impact of CSR activities. Regularly
                            share updates, success stories, and key milestones
                            achieved through collaboration to stakeholders,
                            employees, and the public.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Sixteen')"
                >
                    <h2 class="accordion-header" id="headingSixteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                            (click)="change_accordion('16')"
                        >
                            Regular Evaluation and Adaptation
                            <i class="bx bx-plus-medical" id="plus_16"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFourteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Conduct regular evaluations of CSR initiatives in
                            collaboration with the NGO. Assess the effectiveness
                            of programs, gather feedback from beneficiaries, and
                            adapt strategies as needed to continuously improve
                            the impact of collaborative efforts.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
