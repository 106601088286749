import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";

@Component({
    selector: "app-collaboration-one",
    templateUrl: "./collaboration-one.component.html",
    styleUrls: ["./collaboration-one.component.scss"],
})
export class CollaborationOneComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
    changeAccordion(num) {
        $("#collapseOne").css("display", "none");
        $("#collapseTwo").css("display", "none");
        $("#collapseThree").css("display", "none");
        $("#collapseFour").css("display", "none");
        $("#collapseFive").css("display", "none");
        $("#collapseSix").css("display", "none");
        $("#collapseSeven").css("display", "none");
        $("#collapseEight").css("display", "none");
        $("#collapseNine").css("display", "none");
        $("#collapseTen").css("display", "none");
        $("#collapseEleven").css("display", "none");
        $("#collapseTwelve").css("display", "none");
        $("#collapseThirteen").css("display", "none");
        $("#collapseFourteen").css("display", "none");
        $("#headingFifteen").css("display", "none");
        $("#headingSixteen").css("display", "none");

        $("#collapse" + num).css("display", "block");
    }

    change_accordion(id) {
        $("#plus_1").css("display", "block");
        $("#plus_2").css("display", "block");
        $("#plus_3").css("display", "block");
        $("#plus_4").css("display", "block");
        $("#plus_5").css("display", "block");
        $("#plus_6").css("display", "block");
        $("#plus_7").css("display", "block");
        $("#plus_8").css("display", "block");
        $("#plus_9").css("display", "block");
        $("#plus_10").css("display", "block");
        $("#plus_11").css("display", "block");
        $("#plus_12").css("display", "block");
        $("#plus_13").css("display", "block");
        $("#plus_14").css("display", "block");
        $("#plus_15").css("display", "block");
        $("#plus_16").css("display", "block");

        $("#plus_" + id).css("display", "none");
    }
}
