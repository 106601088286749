<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class="bx bx-map"></i>
                            <a href="#" target="_blank"
                                >The Address”, 1801, B- Block, West Gate, Nr
                                YMCA Club, S.G. Highway, Ahmedabad (Gujarat)-
                                380015</a
                            >
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i>
                            <a href="tel:+11234567890">+91 98255 64942</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a href="mailto:hello@ednuv.com"
                                >info@enviask.com</a
                            >
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"
                                ><i class="bx bxl-pinterest-alt"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <!-- <p>To get the latest news and latest updates from us.</p> -->
                        <p>
                            Connect with Us @
                            <a href="to:info@enviask.com">info@enviask.com</a>
                            For More Inquiry
                        </p>
                        <!-- [formGroup]="myReactiveForm"
                        (ngSubmit)="newsletter_send()" -->
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input
                                type="email"
                                class="input-newsletter"
                                placeholder="Enter your email"
                                name="u_email"
                                id="u_email"
                            />
                            <!-- formControlName="u_email" -->
                            <!-- [ngModel]="u_email" -->
                            <!-- <div
                                [ngClass]="
                                    newsletter_msg_show == false
                                        ? 'display_none'
                                        : newsletter_msg_class
                                "
                            >
                                {{ newsletter_msg }}
                            </div> -->
                            <button type="submit" class="">
                                <div class="newsletter_button">
                                    <span>Subscribe</span>
                                    <div
                                        class="loader"
                                        id="newsletter_loader"
                                    ></div>
                                    <!-- [ngClass]="
                                            loader_show == false
                                                ? 'display_none'
                                                : ''
                                        " -->
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"
                    ><img src="assets/img/logo.png" alt="image"
                /></a>
            </div>
            <p>© 2023 Enviask | All rights reserved.</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
