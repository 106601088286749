<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img
                        src="assets/img/about/about-us-1.webp"
                        class="shadow"
                        alt="image"
                    />
                    <img
                        src="assets/img/about/about-us-2.webp"
                        class="shadow"
                        alt="image"
                    />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <p>
                        We are a group of techno-legal environmental experts
                        committed to providing our services globally to
                        encourage cutting-edge technology in the area of
                        environment protection and sustainable development
                    </p>
                    <p>
                        In whatever business or profession you are,
                        environmental protection is a great liability on every
                        one of us. Environmental costs are skyrocketing at most
                        companies, with little chance of economic payback in
                        sight. Given this reality, they question whether
                        “win-win” solutions should be the foundation of a
                        company’s environmental strategy.
                    </p>
                    <p>
                        We help you in designing your environmental strategy
                        applying the minds of our experts to achieve your
                        sustainable development goals. We care for your
                        “economy” while nourishing global “ecology”.
                    </p>
                    <p>
                        Think of us as an extension of your team using our
                        experience to create results that everyone is proud of.
                    </p>
                    <a
                        routerLink="/courses-2-columns-style-1"
                        class="default-btn"
                        ><i class="bx bx-move-horizontal icon-arrow before"></i
                        ><span class="label">Know More</span
                        ><i class="bx bx-move-horizontal icon-arrow after"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</div>
