<app-header-style-one></app-header-style-one>

<section class="faq py-5 px-xl-5">
    <div class="container py-2">
        <div class="row">
            <h2 class="faq-title mb-3 mb-md-5 gothamb">
                Collaborations Educational Institutes
            </h2>
        </div>
    </div>
    <!-- 
        : 
• : 
• :
•: 
• : 
• : 
• : 
• : 
•: 
     -->
    <div class="container">
        <div class="row mx-auto">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item" (click)="changeAccordion('Five')">
                    <h2 class="accordion-header" id="headingFive">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                            (click)="change_accordion('5')"
                        >
                            Needs Assessment and Goal Alignment
                            <i class="bx bx-plus-medical" id="plus_5"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Begin by conducting a comprehensive needs assessment
                            with the educational institution to understand their
                            environmental goals and challenges. Align our
                            services with their objectives, whether it's
                            reducing carbon footprint, implementing sustainable
                            practices, or integrating green technologies.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Six')">
                    <h2 class="accordion-header" id="headingSix">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            (click)="change_accordion('6')"
                        >
                            Tailored Workshops and Training
                            <i class="bx bx-plus-medical" id="plus_6"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Offer tailored workshops and training sessions for
                            faculty, staff, and students. These sessions can
                            cover a range of topics, including environmental
                            law, sustainable farming practices, circular economy
                            principles, and green technologies. Customizing the
                            content ensures relevance to the institution's
                            specific context.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Seven')">
                    <h2 class="accordion-header" id="headingSeven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            (click)="change_accordion('15')"
                        >
                            Green Campus Audits
                            <i class="bx bx-plus-medical" id="plus_7"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Conduct thorough green campus audits to identify
                            areas for improvement in resource management, waste
                            reduction, and energy efficiency. Provide actionable
                            recommendations and solutions to enhance the
                            institution's overall environmental performance.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eight')">
                    <h2 class="accordion-header" id="headingEight">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            (click)="change_accordion('16')"
                        >
                            Climate-Centric Curriculum Integration
                            <i class="bx bx-plus-medical" id="plus_8"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Collaborate with educational leaders to integrate
                            climate-centric and eco-friendly principles into the
                            curriculum. Offer insights on incorporating relevant
                            case studies, projects, and real-world applications
                            that align with the institution's sustainability
                            goals.
                        </div>
                    </div>
                </div>

                <div class="accordion-item" (click)="changeAccordion('Ten')">
                    <h2 class="accordion-header" id="headingTen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            (click)="change_accordion('10')"
                        >
                            Intellectual Property Guidance
                            <i class="bx bx-plus-medical" id="plus_10"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Provide guidance on intellectual property related to
                            environmental innovations. This can include support
                            for faculty and student research projects, patenting
                            processes for sustainable technologies, and
                            protecting intellectual creations in the
                            environmental sector.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Eleven')">
                    <h2 class="accordion-header" id="headingEleven">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            (click)="change_accordion('11')"
                        >
                            Student Engagement Programs
                            <i class="bx bx-plus-medical" id="plus_11"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseEleven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEleven"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Initiate student engagement programs, such as
                            environmental competitions, hackathons, or research
                            grants focused on climate-centric solutions.
                            Encourage innovation and provide mentorship to
                            students working on projects that contribute to
                            sustainable practices.
                        </div>
                    </div>
                </div>
                <div class="accordion-item" (click)="changeAccordion('Twelve')">
                    <h2 class="accordion-header" id="headingTwelve">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            (click)="change_accordion('12')"
                        >
                            Collaborative Research Projects
                            <i class="bx bx-plus-medical" id="plus_12"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseTwelve"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Explore opportunities for collaborative research
                            projects between ENVIASK LEGAL experts and faculty
                            members. These projects can address current
                            environmental challenges and contribute to the
                            development of sustainable solutions.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Thirteen')"
                >
                    <h2 class="accordion-header" id="headingThirteen">
                        <button
                            class="accordion-button gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="true"
                            aria-controls="collapseThirteen"
                            (click)="change_accordion('13')"
                        >
                            Participation in Sustainability Events
                            <i class="bx bx-plus-medical" id="plus_13"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseThirteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThirteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Actively participate in or organize sustainability
                            events, seminars, and conferences. These events can
                            serve as platforms for knowledge exchange,
                            showcasing success stories, and discussing emerging
                            trends in environmental law, green technologies, and
                            sustainable practices.
                        </div>
                    </div>
                </div>
                <div
                    class="accordion-item"
                    (click)="changeAccordion('Fourteen')"
                >
                    <h2 class="accordion-header" id="headingFourteen">
                        <button
                            class="accordion-button collapsed gothamm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            (click)="change_accordion('14')"
                        >
                            Monitoring and Continuous Improvement
                            <i class="bx bx-plus-medical" id="plus_14"></i>
                        </button>
                    </h2>
                    <div
                        id="collapseFourteen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                    >
                        <div class="accordion-body shadow arimor">
                            Establish a monitoring mechanism to track the
                            progress of implemented solutions. Regularly assess
                            the impact of collaborative initiatives and work
                            with educational institutions to make continuous
                            improvements based on performance metrics.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
